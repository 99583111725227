import Q1ThumbNail from "../../assets/test/test3/thumbnail.png";
import Type0 from "../../assets/test/test3/1.png";
import Type1 from "../../assets/test/test3/2.png";
import Type2 from "../../assets/test/test3/3.png";
import Type3 from "../../assets/test/test3/4.png";

/**
 * 인터페이스 설명
 * {
 *    id: number -------------------- 테스트 식별 아이디
 *    categoryId: number ------------ 카테고리 아이디 (data.js 파일에 있습니다.)
 *    thumbnail: string ------------- 카드 썸네일 입니다. 위에서 import 합니다.
 *    title: string ----------------- 테스트 제목입니다.
 *    subTitle: string -------------- 테스트 부 제목입니다.
 *    description: string ----------- 테스트 설명 입니다.
 *    totalQuestion: number --------- 총 질문 개수 입니다. (없어도 무방, 현재 사용하지 않음)
 *    kakaoShareId: number ---------- 카카오 공유 템플릿 ID
 *    shareImage: string ------------ 카카오 공유 이미지
 *    subjectQuestion: {
 *        subject : string ----------------- 주제 (사용 하지 않음)
 *        shortLabel: string --------------- 라벨 (사용 하지 않음)
 *        positiveResult: string ----------- + 워딩 (사용 하지 않음)
 *        negativeResult: string ----------- - 워딩  (사용 하지 않음)
 *        qeustions: {
 *            no: number -------------------------- 문제 번호
 *            content: string --------------------- 질문
 *            answer: {
 *                constents: string --------------------------- 답변
 *                value: 1 | -1 ------------------------------- 값
 *            }
 *        }[] <----배열이라는 뜻
 *    }[]
 *    result: {
 *        type: string -------------- 결과 type,
 *        const: number ------------- 결과 카운트 (결과 식별자로 사용)
 *        image: string ------------- 결과 이미지 입니다. 위에서 import 합니다.
 *        description: string ------- 결과 설명 입니다.
 *    }[]
 * }
 */
export const test3 = {
  id: 3,
  categoryId: 1,
  thumbnail: Q1ThumbNail,
  title: "티모시 방한기념 테스트 두번째",
  subTitle: "한국에는 왜 오셨습니까?",
  description: `편안함을 느끼는 글쓰기 바운더리를 찾아봅시다.\n\n글쓰기는 개인의 심리와 밀접한 연관이 있다고 믿어요.\n'글감'에 대한 허들이라거나, 글쓰기의 대상물이라거나.\n\n14개의 질문을 통해, 4개의 유형 중\n본인에게 가장 적합한 결과지를 받아보게 됩니다.`,
  totalQuestion: 14,
  kakaoShareId: 102380,
  shareImage:
    "https://img.tvreportcdn.de/cms-content/uploads/2023/07/20/aed98601-04dd-444a-a28b-4ebe5ca6fb97.jpg",
  subjectQuestion: [
    {
      subject: "티모시를 좋아하는 이유",
      shortLabel: "이유",
      positiveResult: "프랜치섹시",
      negativeResult: "프랜치토스트",
      questions: [
        {
          no: 1,
          content: "내한. 오년만. 자리에 앉았다.\n저항감이 확 올라오는데? 왜지",
          answer: [
            {
              contents: "글은 수단일 뿐, 콘텐츠의 본질인 '자랑거리'가 없잖아.\n나는 글감이 없어.",
              value: 1,
            },
            {
              contents: "내 문장이 마음에 안 드는데, 필력이 왜이러지 나는.\n누구는 일상마저 맛깔나게 쓰는데.",
              value: -1,
            },
          ],
        },
        {
          no: 2,
          content: "솔직하게 말하자면 \n모임을 나가거나, 하다못해 단톡방에서라도 나는",
          answer: [
            {
              contents: "다른 사람들의 '우와'라는 반응이 필요하다. 이런 결핍이 있다.",
              value: 1,
            },
            {
              contents: "나를 환호해주고 부러워하는 분위기보다는, \n관계의 깊이나 연결감 등이 더 중요하다.",
              value: -1,
            },
          ],
        },
        {
          no: 3,
          content: "내가 쓰는 글. 풍겨야만 한다고 생각하는 분위기가 있다면.",
          answer: [
            {
              contents: "신뢰, 확신. '이 분야를 잘 아는 사람'의 글처럼 느껴져야 해.",
              value: 1,
            },
            {
              contents: "감정, 감성, 사람틱한 냄새. 남의 일기장 느낌이 나야 해.",
              value: -1,
            },
          ],
        },
        {
          no: 4,
          content: "나는 어떠한 콘텐츠에 더 몰입하고 있을까.\n내 시간을 어디에 쓰고 있을까.",
          answer: [
            {
              contents: "정보, 지식, 노하우, 원칙을 알려주는 무언가",
              value: 1,
            },
            {
              contents: "정답이 없는 무언가. 소설, 에세이, 등 스토리 기반 무언가",
              value: -1,
            },
          ],
        },
        {
          no: 5,
          content: "나는 이런 글을 쓰고싶다. 가능하다면 말이지.\n(첫문장 기준)",
          answer: [
            {
              contents: "내가 이정도의 재산에 도달할 수 있었던 이유는,\n솔직히 운과 노력이 맞아떨어졌기에..",
              value: 1,
            },
            {
              contents: "때로는 크리스마스에도, 악마같은 아이가 태어난다.\n(나의 라임 오렌지나무 中)",
              value: -1,
            },
          ],
        },
        {
          no: 6,
          content: "잘 풀릴 때, 주변에 자랑아닌 자랑을 은근히 흘렸다. \n그리고 하향세인 지금.",
          answer: [
            {
              contents: "조용히, 재도약을 준비하며 잠을 줄인다. \n한 번 해봤으니 자신감도 있고.",
              value: 1,
            },
            {
              contents: "숨지 않고, 결과가 기대에 못 미치는 '지금' 이야기를 한다. \n뭐, 징징댈수도 있고. 밥이나 얻어먹을 핑계 하하.",
              value: -1,
            },
          ],
        },
        {
          no: 7,
          content: "내 주변의 사람들은, \n솔직히 나의 이러한 모습에 끌리는 것 같다.",
          answer: [
            {
              contents: "그럴싸한 성과를 그럭저럭 잘 내니까. \n뭐 시험에 붙는다거나, 새로운 도전을 한다거나.",
              value: 1,
            },
            {
              contents: "대화가 잘 통하니까. \n시덥잖은 이야기로도 두시간은 떠들 수 있으니까.",
              value: -1,
            },
          ],
        },
      ],
    },
    {
      subject: "좋아하는 연예인을 관찰하는 유형",
      shortLabel: "레드카펫유무",
      positiveResult: "직접가서대기",
      negativeResult: "멀리서응원",
      questions: [
        {
          no: 15,
          content: "일상의 시시콜콜한 주제일지라도, \n온라인상에서 나를 드러낸다는 것이 꽤나 무섭다.",
          answer: [
            {
              contents: "그런가. 그냥 툭 올려버리면 된다고 생각하는데. \n뭐 어제 피자집을 다녀왔다거나.",
              value: 1,
            },
            {
              contents: "맞아.\n새로운 피자 브랜드 메뉴 '정보'를 포스팅하는게 더 편하지 솔직히.",
              value: -1,
            },
          ],
        },
        {
          no: 16,
          content: "글쓰기를 통해 무언가를 이뤄내보자 노력했는데, \n매번 미루거나 포기했다면. 이유는",
          answer: [
            {
              contents: "나라는 사람이, 매력이 부족한 것 같기도 하고. \n팬이 없는 것 같기도 해서.",
              value: 1,
            },
            {
              contents: "내가 뭐, 지식이나 노하우를 충분히 보유하고 있어야 하겠더라고.\n경제 책을 더 읽어야겠어.",
              value: -1,
            },
          ],
        },
        {
          no: 17,
          content: "뉴스레터를 운영해야만 하는 상황이네. \n하, 귀찮지만 뭐 시작해볼까.",
          answer: [
            {
              contents: "내 생각, 경험, 관점을 풀어보자. 주제야 뭐 많지. \n돈, 사회생활, 결혼... ",
              value: 1,
            },
            {
              contents: "음, 어려운 경제 용어 풀이라거나, \n요즘 시끌벅절한 주제에 대해 다뤄봐야겠다.",
              value: -1,
            },
          ],
        },
        {
          no: 18,
          content: "만약 내가 그 과정은 모르겠지만 일단 유명해졌다면. \n 제주도에 막 온 나. 받아보는 질문의 뉘앙스는?",
          answer: [
            {
              contents: "갑자기 여행을 가셨네요. 무슨 심경의 변화라도?",
              value: 1,
            },
            {
              contents: "날씨 어때요? 저도 스토리에 올리신 호텔 저번달에 갔었는데.",
              value: -1,
            },
          ],
        },
        {
          no: 19,
          content: "페이를 신경쓰지 않는다면, 내가 더 만족할만한 직업은.\n내 실력은 업계 평균 수준.",
          answer: [
            {
              contents: "평론가\n내 이름을 걸고 무언가를 감상, 평가하는 삶.",
              value: 1,
            },
            {
              contents: "데이터 분석가\n내가 원하는 분야를 골라, 데이터를 분석, 수집, 정리하는 삶.",
              value: -1,
            },
          ],
        },
        {
          no: 20,
          content: "친구들은 내게 이러이러한 질문을 많이 하는 편이다. \n그나마 골라본다면.",
          answer: [
            {
              contents: "선택 앞에서의 고민. 진로라거나 감정이 얽힌 이야기라거나.",
              value: 1,
            },
            {
              contents: "정보. 맛집이라거나, 숙소, 책 등의 추천. 전문적인 지식도 포함.",
              value: -1,
            },
          ],
        },
        {
          no: 21,
          content: "가식없이 이야기하자면, 나는 영향력을 원해. \n 더 자세하게 이야기하자면...",
          answer: [
            {
              contents: "나라는 사람이 갖는 영향력이 더 좋아. 리스크는 있겠지 물론.",
              value: 1,
            },
            {
              contents: "내가 만든 무언가의 영향력. 뭐 앱이라거나, 사이트라거나.",
              value: -1,
            },
          ],
        },
      ],
    },
  ],
  result: [
    {
      type: "",
      count: 0,
      image: Type0,
      description:
        "",
    },
    {
      type: "",
      count: 1,
      image: Type2,
      description:
        "",
    },
    {
      type: "",
      count: 2,
      image: Type1,
      description:
        "",
    },
    {
      type: "",
      count: 3,
      image: Type3,
      description:
        "",
    },
  ],
};
