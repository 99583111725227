import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "../main/component/Card";
import { allTest } from "../../data/data";
import { useEffect, useRef, useState } from "react";
import KakaoImg from "../../assets/image/kakaotalk_shareing.png";
import DisplayAds from "../../components/DisplayAdsFlex";

export const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const questionId = useRef(undefined);
  const [data, setData] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [showTaost, setShowTaost] = useState(false);
  const resultCount = useRef(undefined);

  useEffect(() => {
    const id = location.pathname.split("/")[2];
    const count = location.pathname.split("/")[4];
    questionId.current = id;
    resultCount.current = count;
    if (allTest) {
      setData(allTest.find((item) => `${item.id}` === `${id}`));
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setResult(
        data.result.find((item) => `${item.count}` === `${resultCount.current}`)
      );
    }
  }, [data]);

  if (!data || !result) return null;

  return (
    <TestContainer>
      <ResultImg src={result.image} />
      <ResultType>{result.type}</ResultType>
      <Description>{result.description}</Description>

      <div
        style={{
          // width: "100%",
          marginTop: 36,
          fontSize: 19,
          fontWeight: 400,
        }}
      >
        공유하기
      </div>
      <ShareContainer>
        {data.kakaoShareId && (
          <ShareItem
            onClick={() => {
              window.Kakao.Share.sendDefault({
                objectType: "feed",
                content: {
                  title: data.title,
                  description: data.description,
                  imageUrl: data.shareImage,
                  link: {
                    mobileWebUrl: `https://summer.bluewavenbeach.com/test-main/${data.id}`,
                    webUrl: `https://summer.bluewavenbeach.com/test-main/${data.id}`,
                  },
                },
                buttons: [
                  {
                    title: "유형검사 시작",

                    link: {
                      mobileWebUrl: `https://summer.bluewavenbeach.com/test-main/${data.id}`,
                      webUrl: `https://summer.bluewavenbeach.com/test-main/${data.id}`,
                    },
                  },
                  {
                    title: "공유된 결과보기",

                    link: {
                      mobileWebUrl: `https://summer.bluewavenbeach.com${location.pathname}`,
                      webUrl: `https://summer.bluewavenbeach.com${location.pathname}`,
                    },
                  },
                ],
              });
            }}
          >
            <img className="share__icon" src={KakaoImg} />
            <div className="share__title">카카오톡</div>
          </ShareItem>
        )}
   <CopyToClipboard
          className="Toram"
          text={`https://summer.bluewavenbeach.com/test-main/${data.id}`}
          onCopy={() => {
            setShowTaost(true);

            setTimeout(() => {
              setShowTaost(false);
            }, 1500);
          }}
        >
          <ShareItem>
            <div
              className="share__icon"
              style={{
                border: "1px solid gray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              URL
            </div>
            <div className="share__title">링크복사</div>
          </ShareItem>
        </CopyToClipboard>

        <ShareItem
          onClick={() => {
            const linkToGo = `httpss://blog.naver.com/moistmarketer/223225286890`;
            window.location.href = linkToGo;
          }}
        >
          <div
            className="share__icon"
            style={{
              border: "1px solid gray",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            ETC
          </div>
          <div className="share__title">비슷한향</div>
        </ShareItem>
      </ShareContainer>

      <StartBtn
        style={{ marginTop: 24 }}
        onClick={() => {
          navigate(`/test-main/${questionId.current}`);
        }}
      >
        다시하기
      </StartBtn>

      <TestList>
        {allTest
          .filter(
            (item) => item.categoryid === data.categoryId && item.id !== data.id
          )
          .map((item, index) => (
            <FlexColumn key={index}>
              {index % 3 === 0 && <DisplayAds />}
              <Card key={index} cardItem={item} />
            </FlexColumn>
          ))}
      </TestList>
      <ToastMsg className={showTaost ? "toast__show" : ""}>
        링크 복사를 완료하였습니다.
      </ToastMsg>
    </TestContainer>
  );
};

const TestContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ResultImg = styled.img`
  width: 100%;  // 필요에 따라 화면 폭에 맞게 이 값을 조정하세요
  height: auto; // 이렇게 하면 이미지의 비율이 유지됩니다
  margin: 24px auto;
  max-width: 600px; // 선택사항: 매우 넓은 화면에서 이미지가 너무 크게 보이지 않도록 최대 너비를 설정할 수 있습니다
  display: block; // margin auto가 중앙 정렬에 효과적으로 적용되도록 합니다
  border-radius: 15px; // 이 값을 조정하여 모서리의 둥근 정도를 변경할 수 있습니다

`;

const ResultType = styled.div`
  width: 100%;
  text-align: center;

  color: var(--light-text01, #1f2022);

  /* heading/18-sb */
  font-family: 'GyeonggiBatang';

  font-size: 24px;
  font-style: normal;
  : 400;
  
`;
const Description = styled.pre`
  width: 100%;
  padding: 0 24px;
  margin-top: 10px;
  color: var(--light-text01, #1f2022);

  word-break: break-all;
  white-space: break-spaces;

  /* heading/18-sb */
  font-family: 'GyeonggiBatang';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  
`;

const StartBtn = styled.button`
  width: 222px;
  height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  border: 0;

  background-color: #133337;

  margin: 0 auto;

  font-family: 'GyeonggiBatang';
  font-size: 15px;
  font-weight: 400;
  color: white;

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    background-color: ##130a3e;
  }
`;

const TestList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 48px;
  padding-bottom: 24px;
`;

const ShareContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 10px;
`;

const ShareItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  .share__icon {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    border-radius: 50%;
  }
  .share__title {
    font-size: 14px;
  }
`;

const ToastMsg = styled.div`
  position: fixed;

  width: max-content;
  height: 40px;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 20px;
  padding: 12px 24px;

  line-height: 1;
  font-size: 15px;
  font-weight: 400;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  transition: all 0.2s ease-in;

  &.toast__show {
    bottom: 24px;
  }
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;